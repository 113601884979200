* {
  box-sizing: border-box;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}

.centered {
  width: "100%";
  text-align: center;
}

table:hover,
tr:hover,
thead:hover {
  background: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #d0dfff !important;
}

.ant-table-thead .ant-table-cell {
  background-color: #65a7f8 !important;
  padding-left: 10px;
  padding-right: 10px;
}
.ant-table-tbody .ant-table-cell {
  padding-left: 10px;
  padding-right: 10px;
}
